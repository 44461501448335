import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {NbButtonModule, NbCheckboxModule, NbDialogService, NbFormFieldModule, NbInputModule, NbSpinnerModule,} from '@nebular/theme';
import {UntilDestroy} from '@ngneat/until-destroy';
import {NgxMaskDirective} from 'ngx-mask';
import {filter, map, take, withLatestFrom} from 'rxjs';
import {CountControlComponent, CountControlValueInterface,} from 'src/app/booking/booking-confirmation/count-control/count-control.component';
import {TermsConditionsDialogComponent,} from 'src/app/dialog/terms-conditions-dialog/terms-conditions-dialog.component';
import {AvailableVehicleInterface, EquipmentInterface} from 'src/app/core/interfaces/rates.interfaces';
import {ReservationDTO} from 'src/app/core/interfaces/reservations.interfaces';
import {searchFormToDTO} from 'src/app/core/mappers/rates.mappers';
import {onlyLettersValidator} from 'src/app/core/validators';
import {CarCalculationsService} from 'src/app/core/services/car-calculations.service';
import {ImgPlaceholderDirective} from '../../core/directives/img-placeholder.directive';
import {RatesDataService} from '../../core/services/rates-data.service';
import {ReservationDataService} from '../../core/services/reservation-data.service';
import {SearchResultsDataService} from '../../core/services/search-results-data.service';
import {ErrorMessageComponent} from '../../core/components/error-message/error-message.component';
import {IconListComponent} from '../booking-search-list/icon-list/icon-list.component';
import {FleetInterface} from "../../core/interfaces/fleet.interface";
import {getFleetTerms} from "../../core/helpers";
import {FleetsDataService} from "../../core/services/fleets-data.service";

export interface AppliedEquipmentInterface {
  quantity: number;
  price: number;
  ota_code: number;
  name: string;
}

@UntilDestroy()
@Component({
  selector: 'car-booking-confirmation',
  templateUrl: './booking-confirmation.component.html',
  styleUrls: ['./booking-confirmation.component.scss'],
  providers: [CarCalculationsService],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    NbCheckboxModule,
    NbButtonModule,
    NbSpinnerModule,
    ErrorMessageComponent,
    NbFormFieldModule,
    NgxMaskDirective,
    ReactiveFormsModule,
    FormsModule,
    CountControlComponent,
    IconListComponent,
    ImgPlaceholderDirective,
    NbInputModule,
  ],
})
export class BookingConfirmationComponent {
  agreedWithTerms = false;
  agreedWithFleetTerms = false;

  additionalOptionsForm = this.fb.group({});
  personalForm = this.fb.group({
    first_name: [null, [Validators.required, Validators.maxLength(20), onlyLettersValidator()]],
    last_name: [null, [Validators.required, Validators.maxLength(20), onlyLettersValidator()]],
    email: [null, [Validators.required, Validators.email]],
    phone_number: [null, Validators.required],
    flight_number: [null],
  });

  car$ = this.searchResultDataService.selectedCar$.pipe(filter(Boolean));
  reservationPending$ = this.reservationsDataService.isLoading$;
  bookingDays$ = this.ratesDataService.bookingDays$;
  equipmentTotal$ = this.additionalOptionsForm.valueChanges.pipe(
    withLatestFrom(this.bookingDays$),
    map(([, days]) => this.appliedEquipment.reduce((prev: number, curr: AppliedEquipmentInterface) => prev + curr.price, 0) * days)
  )

  fleetTermsUrl$ = this.fleetsDataService.fleets$.pipe(
    map((fleets: FleetInterface[]) => {
      return getFleetTerms(this.carCalculationsService.selectedCar.vehicle.fleet_id, fleets);
    }),
  );



constructor(
    private fb: UntypedFormBuilder,
    private dialogService: NbDialogService,
    private ratesDataService: RatesDataService,
    private reservationsDataService: ReservationDataService,
    public carCalculationsService: CarCalculationsService,
    public searchResultDataService: SearchResultsDataService,
    private fleetsDataService: FleetsDataService,
  ) {
    this.fleetsDataService.loadFleets();
    this.initAdditionalOptionsForm();
    this.car$
      .pipe(take(1))
      .subscribe((car: AvailableVehicleInterface) =>  this.carCalculationsService.selectedCar = car)
  }

  get disableButton(): boolean {
    return !(this.personalForm.valid && this.agreedWithTerms && this.agreedWithFleetTerms);
  }

  get appliedEquipment(): AppliedEquipmentInterface[] {
    const optionsForm: { [key: string]: CountControlValueInterface } = this.additionalOptionsForm.getRawValue();
    const applied: AppliedEquipmentInterface[] = [];

    Object.entries(optionsForm).forEach((item: [string, CountControlValueInterface]) => {
      const equipment = JSON.parse(item[0]) as EquipmentInterface;
      applied.push({
        name: equipment.description,
        ota_code: equipment.ota_code,
        ...item[1],
      });
    });

    return applied.filter((item) => item.quantity > 0);
  }

  countControlName(item: EquipmentInterface): string {
    return JSON.stringify(item);
  }

  openTermsConditions(): void {
    this.dialogService.open(TermsConditionsDialogComponent);
  }

  makeReservation() {
    this.searchResultDataService.searchForm$
      .pipe(
        filter(Boolean),
        withLatestFrom(this.car$),
        take(1),
      )
      .subscribe(([searchForm, car]) => {
        const personalForm = this.personalForm.getRawValue();

        const reservation = {
          ...searchFormToDTO(searchForm),
          primary_driver: {
            first_name: personalForm.first_name,
            last_name: personalForm.last_name,
            phone_number: personalForm.phone_number,
            email: personalForm.email,
            country_code: 'GB',
          },
          equipment: this.appliedEquipment.map(item => ({
            ota_code: +item.ota_code,
            quantity: item.quantity,
          })),
          flight_number: personalForm.flight_number,
          quotation_id: car.quotation_id,
        } as unknown as ReservationDTO;
        this.reservationsDataService.makeReservation(reservation);
      });
  }

  private initAdditionalOptionsForm(): void {
    this.car$
      .pipe(
        map((car: AvailableVehicleInterface) => car.equipment),
        take(1),
      )
      .subscribe((items: EquipmentInterface[]) => {
        items.forEach((item: EquipmentInterface) => {
          this.additionalOptionsForm.addControl(this.countControlName(item), new UntypedFormControl({
            price: 0,
            quantity: 0,
          }));
        });
      });
  }
}
