import { differenceInMinutes, endOfYesterday, isAfter, set } from 'date-fns';
import { DeskInterface } from './interfaces/desks.interfaces';
import {
  ChargeInterface,
  VehicleChargeInterface,
} from 'src/app/core/interfaces/rates.interfaces';
import { FleetInterface } from './interfaces/fleet.interface';
import Fuse from "fuse.js";

export const datesAfterToday = (date: Date | string): boolean => {
  return isAfter(new Date(date), endOfYesterday());
}

export const handeDeskView = (value: DeskInterface | string): string =>
  typeof value === 'string' ? value : [value.name, value.city, value.state || ''].filter(Boolean).join(', ');

export const isWrongInterval = (
  pickUpDate: Date | null,
  pickUpTime: Date | null,
  dropOffDate: Date | null,
  dropOffTime: Date | null
): boolean => {
  if (!pickUpDate || !pickUpTime || !dropOffDate || !dropOffTime) return false;

  const pickUp = mergeDateAndTime(pickUpDate, pickUpTime);
  const dropOff = mergeDateAndTime(dropOffDate, dropOffTime);

  if (isAfter(pickUp, dropOff)) return true;
  return differenceInMinutes(pickUp, dropOff) > -59;
}

export const getAgeMask = (age: number | null): string => {
  return age && age >= 35 && age <= 65 ? '00-00' : '00';
};

export const mergeDateAndTime = (date: Date | string, time: Date | string): Date => {
  const timeDate = new Date(time);
  const hours = timeDate.getHours();
  const minutes = timeDate.getMinutes();
  return set(new Date(date), { hours, minutes, seconds: 0 });
}

export function filterByStartsWith<T>(entities: T[], propName: (keyof T)[], filterValue: string): T[] {
  return entities.filter((entity) => {
    const propValues = propName.map((name) => entity[name]).filter(Boolean);
    return propValues.some((item) => startsWith(item as Extract<typeof item, string>, filterValue));
  });
}
export function fuzzySearch<T>(entities: T[], propName: (keyof T)[], filterValue: string): T[] {
  const fuse = new Fuse(entities, {
    includeScore: true,
    keys: ['name', 'city', 'state']
  });

  return fuse.search(filterValue).map((item: any) => item.item);
}

export function startsWith(item: string, part: string | unknown): boolean {
  return typeof part === 'string' ? item.toLowerCase().startsWith(part.toLowerCase()) : false;
}

export function checkACRISS(acriss: string, index: number, values: string[]): boolean {
  const code = acriss[index].toUpperCase();
  return values.map((letter: string) => letter.toUpperCase()).includes(code);
}

export function getPriceWithVAT(charge: VehicleChargeInterface | ChargeInterface): number {
  return +charge.amount + +charge.tax_amount.total;
}

export function getFleetLogoUrlByIdAndRateGroup(id: number, rateGroup: string, fleets: FleetInterface[]): string | undefined {
  return fleets.find((fleet) => fleet.id === id && fleet.rate_group === rateGroup)?.logo;
}

export function getFleetTerms(id: number, fleets: FleetInterface[]): string | undefined {
  return fleets.find((fleet) => fleet.id === id)?.terms;
}
